import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { handleOnChange, sendRequest } from "../../../util/util";
import Loading from "../../Loading";
import { useParams, Navigate } from "react-router-dom";
import ContentLoading from "../../ContentLoading";

export default function UpdatePostForm() {
  const postId = useParams().id;

  validateId(postId);

  const [isLoading, setIsLoading] = useState(() => false);
  const [postData, setPostData] = useState(() => ({}));
  const [contentIsLoading, setContentIsLoading] = useState(() => true);

  const [formData, setFormData] = useState(() => {
    return {
      data: {
        title: "",
        content: "",
        thumbnail: null,
        quizz: {
          isActive: false,
          question: "",
          answers: {
            firstAnswer: "",
            secondAnswer: "",
            thirdAnswer: "",
            fourthAnswer: "",
          },
        },
      },
      status: {
        success: false,
        errors: {
          title: {
            error: false,
            msg: "Title Error",
          },
          content: {
            error: false,
            msg: "Content Error",
          },
          thumbnail: {
            error: false,
            msg: "Thumbnail Error",
          },
          question: {
            error: false,
            msg: "Question Error",
          },
          firstAnswer: {
            error: false,
            msg: "First Answer Error",
          },
          secondAnswer: {
            error: false,
            msg: "Second Answer Error",
          },
          thirdAnswer: {
            error: false,
            msg: "Third Answer Error",
          },
          fourthAnswer: {
            error: false,
            msg: "Fourth Answer Error",
          },
        },
      },
    };
  });

  useEffect(() => {
    getPostData(postId, setPostData, setContentIsLoading, setFormData);
  }, []);

  if (!validateId(postId)) {
    return <Navigate to="/not-found" replace />;
  }

  return Object.keys(postData).length >= 1 ? (
    <form
      className="flex flex-col gap-4 relative"
      encType="multipart/form-data"
      noValidate
      onSubmit={(event) =>
        handleOnSubmit(event, formData, setFormData, setIsLoading, postId)
      }
    >
      <div className="flex flex-col gap-2">
        <label htmlFor="title" className="font-bold text-blue-900 text-2xl">
          Título:
        </label>
        <input
          id="title"
          type="text"
          name="title"
          onChange={(event) => handleOnChange(event.target, setFormData)}
          value={formData.data.title}
          className="p-2 text-tertiary font-normal text-base border border-customTransparent rounded-lg"
        />

        {formData.status.errors.title.error && (
          <p className="text-base font-medium text-red-500">
            {formData.status.errors.title.msg}
          </p>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <h2 className="font-bold text-blue-900 text-2xl">Conteúdo</h2>

        <ReactQuill
          name="content"
          theme="snow"
          value={formData.data.content}
          onChange={(content) => handleReactQuillChange(content, setFormData)}
        />

        {formData.status.errors.content.error && (
          <p className="text-base font-medium text-red-500">
            {formData.status.errors.content.msg}
          </p>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <h2 className="font-bold text-blue-900 text-2xl">Thumbnail:</h2>

        <label
          htmlFor="thumbnail"
          className="text-base font-medium text-tertiary p-2 w-full border cursor-pointer border-customTransparent"
        >
          {formData.data.thumbnail
            ? formData.data.thumbnail.name
            : "Escolher imagem"}
        </label>

        <input
          onChange={(event) => handleOnChange(event.target, setFormData)}
          className="hidden"
          id="thumbnail"
          type="file"
          name="thumbnail"
          accept=".file,.jpeg,.jpg,.webp,.png"
        />

        {formData.status.errors.thumbnail.error && (
          <p className="text-base font-medium text-red-500">
            {formData.status.errors.thumbnail.msg}
          </p>
        )}
      </div>

      <div className="flex gap-3 items-center">
        <label htmlFor="quizz" className="font-bold text-blue-900 text-2xl">
          Adicionar Quizz:
        </label>

        <div
          onClick={() => handleActiveQuizz(setFormData)}
          className={`w-6 h-6 border border-blue-900 ${
            formData.data.quizz.isActive && "bg-blue-900"
          } rounded-sm cursor-pointer`}
        ></div>

        <input
          id="quizz"
          type="checkbox"
          name="quizz"
          className="hidden"
          onChange={(event) => handleOnChange(event.target, setFormData)}
          checked={formData.data.quizz.isActive}
        />
      </div>

      {formData.data.quizz.isActive && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <label
              htmlFor="question"
              className="font-bold text-blue-900 text-2xl"
            >
              Pergunta:
            </label>
            <input
              id="question"
              type="text"
              name="question"
              onChange={(event) => handleQuizzValues(event, setFormData)}
              value={formData.data.quizz.question}
              className="p-2 text-tertiary font-normal text-base border border-customTransparent rounded-lg"
            />

            {formData.status.errors.question.error && (
              <p className="text-base font-medium text-red-500">
                {formData.status.errors.question.msg}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label
              htmlFor="first-answer"
              className="font-bold text-blue-900 text-2xl"
            >
              Resposta 1:
            </label>
            <input
              id="first-answer"
              type="text"
              name="firstAnswer"
              onChange={(event) => handleQuizzValues(event, setFormData)}
              value={formData.data.quizz.answers.firstAnswer}
              className="p-2 text-tertiary font-normal text-base border border-customTransparent rounded-lg"
            />

            {formData.status.errors.firstAnswer.error && (
              <p className="text-base font-medium text-red-500">
                {formData.status.errors.firstAnswer.msg}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label
              htmlFor="second-answer"
              className="font-bold text-blue-900 text-2xl"
            >
              Resposta 2:
            </label>
            <input
              id="second-answer"
              type="text"
              name="secondAnswer"
              onChange={(event) => handleQuizzValues(event, setFormData)}
              value={formData.data.quizz.answers.secondAnswer}
              className="p-2 text-tertiary font-normal text-base border border-customTransparent rounded-lg"
            />

            {formData.status.errors.secondAnswer.error && (
              <p className="text-base font-medium text-red-500">
                {formData.status.errors.secondAnswer.msg}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label
              htmlFor="third-answer"
              className="font-bold text-blue-900 text-2xl"
            >
              Resposta 3:
            </label>
            <input
              id="third-answer"
              type="text"
              name="thirdAnswer"
              onChange={(event) => handleQuizzValues(event, setFormData)}
              value={formData.data.quizz.answers.thirdAnswer}
              className="p-2 text-tertiary font-normal text-base border border-customTransparent rounded-lg"
            />

            {formData.status.errors.thirdAnswer.error && (
              <p className="text-base font-medium text-red-500">
                {formData.status.errors.thirdAnswer.msg}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-2">
            <label
              htmlFor="fourth-answer"
              className="font-bold text-blue-900 text-2xl"
            >
              Resposta 4:
            </label>
            <input
              id="fourth-answer"
              type="text"
              name="fourthAnswer"
              onChange={(event) => handleQuizzValues(event, setFormData)}
              value={formData.data.quizz.answers.fourthAnswer}
              className="p-2 text-tertiary font-normal text-base border border-customTransparent rounded-lg"
            />

            {formData.status.errors.fourthAnswer.error && (
              <p className="text-base font-medium text-red-500">
                {formData.status.errors.fourthAnswer.msg}
              </p>
            )}
          </div>
        </div>
      )}

      <button
        className="p-2 font-bold text-white bg-blue-900 rounded-lg text-base hover:bg-blue-950 transition-all duration-200"
        type="submit"
      >
        Salvar
      </button>

      {formData.status.success && (
        <h3 className="text-lg font-medium text-green-500">
          Post atualizado com Sucesso!
        </h3>
      )}

      {isLoading && (
        <div className="absolute z-10 flex flex-col items-center justify-center top-0 bottom-0 left-0 right-0 bg-transparentBlack">
          <div>
            <Loading />
          </div>
        </div>
      )}
    </form>
  ) : (
    <div className="pt-10 pb-10 flex flex-col items-center justify-center">
      {contentIsLoading ? (
        <ContentLoading />
      ) : (
        <h1 className="text-lg font-bold text-center text-red-500 sm:text-2xl">
          Post não encontrado
        </h1>
      )}
    </div>
  );
}

function validateId(postId) {
  const idValidated = parseInt(postId);
  if (Number.isNaN(idValidated) || idValidated <= 0) {
    return false;
  }

  return true;
}

async function getPostData(
  postId,
  setPostData,
  setContentIsLoading,
  setFormData
) {
  const requestData = {
    method: "GET",
    url: `posts/${postId}`,
  };

  try {
    setContentIsLoading(() => true);

    const response = await sendRequest(requestData);

    if (!response.success) {
      return;
    }

    const responsePostData = response.data;

    setPostData(() => responsePostData);

    setFormData((prevFormData) => {
      const newFormData = {
        title: responsePostData.title,
        content: responsePostData.content,
        thumbnail: { name: responsePostData.thumbnail, notImage: true },
      };

      if (responsePostData.quizz) {
        const answers = JSON.parse(responsePostData.quizz.answers);
        newFormData.quizz = {
          isActive: true,
          question: responsePostData.quizz.question,
          answers: {
            firstAnswer: answers[0],
            secondAnswer: answers[1],
            thirdAnswer: answers[2],
            fourthAnswer: answers[3],
          },
        };
      }

      return {
        ...prevFormData,

        data: {
          ...prevFormData.data,
          ...newFormData,
        },
      };
    });
  } catch (error) {
    window.alert(`Houve um erro no servidor ${error}`);
  } finally {
    setContentIsLoading(() => false);
  }
}

function handleQuizzValues(event, setFormData) {
  const target = event.target;
  const targetValue = target.value;
  const targetName = target.name;

  if (targetName === "question") {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        data: {
          ...prevFormData.data,
          quizz: {
            ...prevFormData.data.quizz,
            question: targetValue,
          },
        },
      };
    });
  } else {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        data: {
          ...prevFormData.data,
          quizz: {
            ...prevFormData.data.quizz,
            answers: {
              ...prevFormData.data.quizz.answers,
              [targetName]: targetValue,
            },
          },
        },
      };
    });
  }
}

function handleActiveQuizz(setFormData) {
  setFormData((prevFormData) => {
    return {
      ...prevFormData,
      data: {
        ...prevFormData.data,
        quizz: {
          ...prevFormData.data.quizz,
          isActive: !prevFormData.data.quizz.isActive,
        },
      },
    };
  });
}

function handleReactQuillChange(content, setFormData) {
  setFormData((prevFormData) => {
    return {
      ...prevFormData,
      data: {
        ...prevFormData.data,
        content,
      },
    };
  });
}

function handleOnSubmit(event, formData, setFormData, setIsLoading, postId) {
  event.preventDefault();

  setIsLoading(() => true);

  handleRequest(formData, setFormData, setIsLoading, postId);
}

async function handleRequest(formData, setFormData, setIsLoading, postId) {
  const formDataToSend = new FormData();

  formDataToSend.append("title", formData.data.title);
  formDataToSend.append("content", formData.data.content);

  if (formData.data.thumbnail) {
    if (formData.data.thumbnail.notImage) {
    } else {
      formDataToSend.append("thumbnail", formData.data.thumbnail);
    }
  }

  if (formData.data.quizz.isActive) {
    formDataToSend.append("hasQuizz", true);

    formDataToSend.append("question", formData.data.quizz.question);

    formDataToSend.append(
      "firstAnswer",
      formData.data.quizz.answers.firstAnswer
    );
    formDataToSend.append(
      "secondAnswer",
      formData.data.quizz.answers.secondAnswer
    );
    formDataToSend.append(
      "thirdAnswer",
      formData.data.quizz.answers.thirdAnswer
    );
    formDataToSend.append(
      "fourthAnswer",
      formData.data.quizz.answers.fourthAnswer
    );
  }

  const requestData = {
    method: "POST",
    url: `posts/${postId}`,
    body: formDataToSend,
    dataForm: true,
  };

  try {
    const response = await sendRequest(requestData);

    if (!response.success) {
      setFormData((prevFormData) => {
        const errors = response.errors;
        const newFormData = { ...prevFormData };

        for (let key in prevFormData.status.errors) {
          if (errors[key]) {
            newFormData.status.errors[key] = {
              error: true,
              msg: errors[key],
            };
          } else {
            newFormData.status.errors[key] = {
              error: false,
              msg: `${key} error.`,
            };
          }
        }

        return newFormData;
      });

      return;
    }

    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        status: {
          success: true,
          errors: {
            title: {
              error: false,
              msg: "Title Error",
            },
            content: {
              error: false,
              msg: "Content Error",
            },
            thumbnail: {
              error: false,
              msg: "Thumbnail Error",
            },
            question: {
              error: false,
              msg: "Question Error",
            },
            firstAnswer: {
              error: false,
              msg: "First Answer Error",
            },
            secondAnswer: {
              error: false,
              msg: "Second Answer Error",
            },
            thirdAnswer: {
              error: false,
              msg: "Third Answer Error",
            },
            fourthAnswer: {
              error: false,
              msg: "Fourth Answer Error",
            },
          },
        },
      };
    });

    setTimeout(() => {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          status: {
            ...prevFormData.status,
            success: false,
          },
        };
      });
    }, 5000);
  } catch (error) {
    window.alert(`Houve um erro no servidor ${error}`);
  } finally {
    setIsLoading(() => false);
  }
}
