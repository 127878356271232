import { Link } from "react-router-dom";
import TablePosts from "../../../components/admin/posts/TablePosts";
import { useState, useEffect } from "react";
import { sendRequest } from "../../../util/util";
import Loading from "../../../components/Loading";
import DeletePost from "../../../components/admin/posts/DeletePost";

export default function Posts() {
  const [postsData, setPostsData] = useState(() => []);
  const [isLoading, setIsLoading] = useState(() => false);
  const [deleteContainer, setDeleteContainer] = useState(() => {
    return {
      isActive: false,
      postName: "Post name to delete.",
      postId: 0,
      success: false,
    };
  });
  const [deleteLoading, setDeleteLoading] = useState(() => false);

  useEffect(() => {
    getPosts(setPostsData, setIsLoading);
  }, []);

  return (
    <section>
      <div className="flex flex-col gap-10">
        <div>
          <Link
            to="novo-post"
            className="p-2 text-nowrap pl-12 pr-12 bg-primary hover:bg-blue-900 transition-all duration-200 text-white font-bold cursor-pointer rounded-lg"
          >
            Novo Post
          </Link>
        </div>

        {deleteContainer.isActive && (
          <DeletePost
            deleteLoading={deleteLoading}
            closeDeleteContainer={() =>
              closeDeleteContainer(setDeleteContainer)
            }
            handleDeletePost={() =>
              handleDeletePost(
                deleteContainer.postId,
                setDeleteContainer,
                setPostsData,
                setIsLoading,
                setDeleteLoading
              )
            }
            postName={deleteContainer.postName}
            success={deleteContainer.success}
          />
        )}

        {isLoading ? (
          <div className="flex flex-col items-center justify-center">
            <Loading usePrimary={true} />
          </div>
        ) : (
          <div>
            {postsData.length >= 1 ? (
              <div className="w-full overflow-auto">
                <TablePosts
                  postsData={postsData}
                  handleDeleteContainer={(postName, postId) =>
                    handleDeleteContainer(setDeleteContainer, postName, postId)
                  }
                />
              </div>
            ) : (
              <h2 className="text-2xl text-center text-red-500 font-bold ">
                Nenhum post encontrado
              </h2>
            )}
          </div>
        )}
      </div>
    </section>
  );
}

async function getPosts(setPostsData, setIsLoading) {
  const requestData = {
    method: "GET",
    url: "posts",
  };

  setIsLoading(() => true);

  try {
    const response = await sendRequest(requestData);

    if (!response.success) {
      return;
    }

    setPostsData(() => response.data);
  } catch (error) {
    window.alert(`Houve um erro no servidor. ${error}`);
  } finally {
    setIsLoading(() => false);
  }
}

function closeDeleteContainer(setDeleteContainer) {
  setDeleteContainer((prevDeleteContainer) => {
    return { ...prevDeleteContainer, isActive: false, success: false };
  });
}

function handleDeleteContainer(setDeleteContainer, postName, postId) {
  setDeleteContainer((prevDeleteContainer) => {
    return {
      postName,
      isActive: true,
      postId,
      success: false,
    };
  });
}

async function handleDeletePost(
  id,
  setDeleteContainer,
  setPostsData,
  setIsLoading,
  setDeleteLoading
) {
  const requestData = {
    method: "DELETE",
    url: `posts/${id}`,
  };

  try {
    setDeleteLoading(() => true);
    const response = await sendRequest(requestData);

    if (!response.success) {
      throw new Error(response.msg);
    }

    setDeleteContainer((prevDeleteContainer) => {
      return {
        ...prevDeleteContainer,
        success: true,
      };
    });

    getPosts(setPostsData, setIsLoading);
  } catch (error) {
    window.alert(`Houve um erro no servidor. ${error}`);
  } finally {
    setDeleteLoading(() => false);
  }
}
